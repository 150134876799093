body,
html {
  margin: 0;
  display: block;
  background-color: #fbf8f8;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}

/* General font size */
.gfs {
  font-size: 16px;
}
.glh {
  line-height: 30px;
}

.full-width {
  width: 100%;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-70 {
  margin-top: 70px;
}

.mr-9 {
  margin-right: 9px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-70 {
  margin-bottom: 70px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 30px;
}

.ml-48 {
  margin-left: 48px;
}

.p-20 {
  padding: 20px;
}
.p-40 {
  padding: 40px;
}
.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}

.right-aligned-buttons {
  display: flex;
  justify-content: right;
  gap: 10px;
}

.left-aligned-buttons {
  display: flex;
  gap: 10px;
}

.pointer {
  cursor: pointer;
}

.aic-g5 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.transparent-div {
  background: rgba(0, 0, 0, 0.10);
}

.service-box-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 70px;
}

.service-box-container .service-box {
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .gutter-sm {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.lp-info-img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.15);
}

.mh-250 {
  min-height: 250px;
}

.mw-300 {
  min-width: 300px;
}
.loader-flex-container {
  height: 30vh;
  margin-right: 100px;
  width: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #d7dcdd;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #1d7f92;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 
