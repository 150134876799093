.loginContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.administration-cards:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
  transform: scale(1.01);
}

.ant-table-row-expand-icon-cell {
  background-color: #121f23 !important;
  border: none !important;
}

.expanded-row-shift-right {
  padding-left: 20px !important;
}
